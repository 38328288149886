import React from "react";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { ModuleWrapper } from "../moduleWrapper";
import { SanityImage } from "@components/sanity/sanityImage";
import { ProductPrice } from "@components/product/productPrice";
import { SaleBadge } from "@components/product/saleBadge";

const TopProductCard = ({
  slug,
  title,
  bottleShot,
  productDetail,
  price,
  comparePrice,
  bgColor,
}) => {
  return (
    <Link
      to={`/products/${slug && slug.current}`}
      className="relative grid group rounded-xl overflow-hidden text-black"
    >
      {/* Sale Badge */}
      <SaleBadge
        className="absolute top-4 left-4 z-10"
        specialText="Top Seller"
        isSpecial
        size="large"
      />
      {/* Opacity */}
      <span
        className="aspect-w-6 aspect-h-7 col-start-1 row-start-1 w-full opacity-50 group-hover:opacity-100 transition-all duration-700"
        style={{ backgroundColor: bgColor && bgColor.hex }}
      />
      <div className="row-start-1 col-start-1 relative z-10 self-center p-10">
        {/* Bottle */}
        <div className="relative z-10 max-w-lg mx-auto">
          <SanityImage image={bottleShot} width={600} />
        </div>
        {/* Details */}
        <div className="text-center relative z-10">
          <h4 className="blockH4 font-medium">{title}</h4>
          <h5 className="blockH6 font-medium my-4">{productDetail}</h5>
          <span className="btn gap-x-2">
            <ProductPrice price={price} comparePrice={comparePrice} />
            <span>-</span>
            <span>Buy Now</span>
          </span>
        </div>
      </div>
    </Link>
  );
};

const RunnerUpCard = ({
  bottleShot,
  title,
  price,
  comparePrice,
  bgColor,
  slug,
  index,
}) => {
  return (
    <Link
      to={`/products/${slug && slug.current}`}
      className="group relative p-4 flex rounded-lg overflow-hidden"
    >
      {/* Bg */}
      <span
        className="absolute w-full h-full opacity-50 group-hover:opacity-100 transition-all duration-700 z-0 top-0 left-0"
        style={{ backgroundColor: bgColor && bgColor.hex }}
      />
      <div className="flex items-center gap-x-1">
        {/* Index */}
        <span className="relative z-10 font-medium text-md">{index + 1}</span>
        {/* Bottle shot */}
        <div className="w-20 flex-shrink-0 relative z-10 lg:w-32">
          <div className="aspect-w-5 aspect-h-4 relative">
            <SanityImage
              image={bottleShot}
              className="absolute inset-0 object-contain"
              width={100}
              height={100}
            />
          </div>
        </div>
        {/* Info */}
        <div className="z-10 relative text-left">
          <h4 className="font-medium blockH4 lg:text-xl">{title}</h4>
          <div className="blockH5 font-medium mt-1">
            <ProductPrice price={price} comparePrice={comparePrice} />
          </div>
        </div>
      </div>
    </Link>
  );
};

const TopProductsList = ({ title, products, config }) => {
  const breakpoints = useBreakpoint();

  const runnersUp = products.slice(breakpoints.lg ? 1 : 0);
  return (
    <ModuleWrapper {...config}>
      <div className="px-gutter py-6">
        <h2 className="blockH1 text-center mb-one md:mb-three">{title}</h2>
        <div className="grid grid-cols-1 w-full gap-4 lg:grid-cols-2 lg:gap-6">
          {/* Top Seller */}
          {breakpoints.lg && <TopProductCard {...products[0]} />}
          {/* Runners Up */}
          <div className="grid gap-y-2 lg:gap-y-6">
            {runnersUp.map((item, index) => (
              <RunnerUpCard {...item} index={index} key={index} />
            ))}
          </div>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default TopProductsList;
